import {createI18n} from "vue-i18n";

export default createI18n({
    legacy: false,
    locale: "sv",
    messages: {
        sv: {
            umpire: "Domare",
            login: "Logga in",
            loading: "Laddar",
            ring: "Matta",
            jury: "Jury",
            corner: "Kant",
            back: "Tillbaka",
            or: "Eller",
            quick_login: "Snabb inloggning",
            log: "Logg",
            points: "Poäng",
            exit: "Lämna",
            remove_point: "Ta bort poäng",
            deductions: "Avdrag",
            total: "Totalt",
            ok_redirect: "Okej. skickar dig vidare!",
            no_unauthorized: "Nope. Obehörig.",
            enter_key: "Ange nyckel",
            go: "Go!",
            logout: "Logga ut",
            reset: "Återställ",
            endpoint: "Endpoint",
            settings:" Inställningar",
            submit: "Skicka",
            demo: "Demo",
            reset_messages: "Återställ meddelanden",
            sparring: "Sparring",
            pattern: "Mönster",
            clear_action_required: "Klarmarkera varning",
            number: "Nummer",
            red: "Röd",
            blue: "Blå",
            zero_pattern: "Nolla mönster",
        },
        en: {
            umpire: "Umpire",
            login: "Login",
            loading: "Loading",
            ring: "Ring",
            jury: "Jury",
            corner: "Corner",
            back: "Back",
            or: "Or",
            quick_login: "Quick Login",
            log: "Log",
            points: "Points",
            exit: "Exit",
            remove_point: "Remove Point",
            deductions: "Deductions",
            total: "Total",
            ok_redirect: "Ok. Redirecting",
            no_unauthorized: "Nope. Unauthorized!",
            enter_key: "Enter key",
            go: "Go!",
            logout: "Logout",
            reset: "Reset",
            endpoint: "Endpoint",
            settings:" Settings",
            submit: "Submit",
            demo: "Demo",
            reset_messages: "Reset messages",
            sparring: "Sparring",
            pattern: "Pattern",
            clear_action_required: "Clear action required",
            number: "Number",
            red: "Red",
            blue: "Blue",
            zero_pattern: "Zero pattern",
        },
    }
})
