<template>
  <h2>{{ t("jury") }}</h2>

  <div class="pt-4">
    <hr>
    <router-link :to="{name: 'competition', params: {key: data.key}}" class="btn">{{ t("back") }}</router-link>
  </div>

</template>

<script setup>
import {reactive} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const route = useRoute()
const data = reactive({
  key: route.params.key
})

</script>
