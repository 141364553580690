import {createRouter, createWebHistory} from "vue-router"
import Login from "./Views/Login"
import QuickLogin from "./Views/QuickLogin"
import Home from "./Views/Home"
import Competition from "./Views/Competition"
import Jury from "./Views/Jury"
import Corner from "./Views/Corner"
import Settings from "./Views/Settings"
import Log from "./Views/Log"

// TODO: There are components, not views...
import DemoSparring from "./Components/Sparring"
import DemoPattern from "./Components/Pattern"
import DemoPoint from "./Components/Point"
import Sparring from "@/Views/Sparring.vue";
import Pattern from "@/Views/Pattern.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: "", name: "home", component: Home},
        {path: "/login", name: "login", component: Login},
        {path: "/ql", name: "quick-login", component: QuickLogin},
        {path: "/settings", name: "settings", component: Settings},
        {path: "/log", name: "log", component: Log},
        {path: "/demo/sparring", name: "demo-sparring", component: DemoSparring},
        {path: "/demo/pattern", name: "demo-pattern", component: DemoPattern},
        {path: "/demo/point", name: "demo-point", component: DemoPoint},
        {path: "/:key", name: "competition", component: Competition},
        {path: "/:key/:ringId/jury", name: "jury", component: Jury},
        {path: "/:key/:ringId/corner", name: "corner", component: Corner},
        {path: "/:key/:ringId/sparring", name: "sparring", component: Sparring},
        {path: "/:key/:ringId/pattern", name: "pattern", component: Pattern},
    ],
})

export default router
