<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <settings-trigger route-name="settings">
          <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="200" alt="JIRUGI">
        </settings-trigger>
        <h2 class="pb-5">{{ t('umpire')}} <small>- {{ data.email }}</small></h2>

        <div v-if="data.loadingCompetitions">{{ t("loading")}}...</div>
        <div v-else v-for="c in data.competitions">
          <router-link :to="{name: 'competition', params: {key: c.key}}" class="btn btn-primary pb-2">
            {{ c.name }}
          </router-link>
        </div>

        <hr>

        <div class="input-group mb-3">
          <input @keyup.enter.prevent="submitKey" type="text" class="form-control" :placeholder="t('enter_key')" v-model="data.key">
          <button @click.prevent="submitKey" class="btn btn-outline-secondary" type="button">{{ t('go')}}</button>
        </div>

        <hr>

        <button class="btn btn-danger" @click.prevent="logout">{{ t('logout') }}</button>

      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive} from "vue";
import {useRouter} from "vue-router";
import axios from "axios";
import common from "@/common";
import {useI18n} from "vue-i18n";
import Settings from "@/Views/Settings.vue";
import SettingsTrigger from "@/Components/SettingsTrigger.vue";

const {t} = useI18n()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  email: sessionStorage.getItem("email"),
  loadingCompetitions: true,
  competitions: [],
  key: "",
})

function logout() {
  sessionStorage.removeItem("accessToken")
  sessionStorage.removeItem("email")
  router.push({name: "login"})
}

function submitKey() {
  router.push({name: "competition", params: {key: data.key}})
}

onMounted(() => {
  axios.post(common.jirugiApi() + "/v1/get_competitions").then(resp => {
    data.competitions = resp.data.competitions
    data.loadingCompetitions = false
  })
})
</script>
