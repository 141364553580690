<template>
  <div class="container p-4">
    <div class="row">
      <div class="col-md-4 offset-md-4">

        <div class="text-center">
          <settings-trigger route-name="settings">
            <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="250" alt="JIRUGI">
          </settings-trigger>
          <h2 class="pb-3">{{ t("umpire") }}</h2>
        </div>

        <form @submit.prevent="login">

          <div v-if="data.errorMessage !== ''" class="alert alert-danger">
            {{ data.errorMessage }}
          </div>

          <input type="text" v-model="data.email" class="form-control mb-1">
          <input type="password" v-model="data.password" class="form-control mb-1">

          <div class="d-grid gap-2">
            <button type="submit" :class="{'is-loading': data.loading}" class="btn btn-primary btn-lg">
              {{ t("login") }}
            </button>
          </div>

          <div class="text-center p-2">
            {{ t("or") }}
          </div>

          <div class="d-grid gap-2">
            <router-link :to="{name: 'quick-login'}" class="btn btn-primary btn-lg">
              {{ t("quick_login") }}
            </router-link>
          </div>

        </form>

      </div>
    </div>
  </div>

</template>

<script setup>
import axios from "axios";
import {reactive} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import common from "@/common";
import SettingsTrigger from "@/Components/SettingsTrigger.vue";

const {t} = useI18n()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  errorMessage: "",
  email: "",
  password: "",
  settingsCounter: 0,
})

function login() {
  axios.post(common.jirugiApi() + "/v1/login", {
    email: data.email,
    password: data.password,
  }).then(resp => {
    if (resp.data.token === undefined) {
      data.errorMessage = "Something happened. Try again later."
      return
    }
    sessionStorage.setItem("accessToken", resp.data.token)
    sessionStorage.setItem("email", resp.data.email)
    axios.defaults.headers.post['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`;
    if (typeof js_bridge !== 'undefined') {
      js_bridge.storageSet("accessToken", resp.data.token)
    }
    router.push({name: "home"})
  }).catch(error => {
    if (error.response.status === 401) {
      data.errorMessage = "Wrong credentials"
    } else {
      data.errorMessage = "Something happened. Try again later."
    }
  })
}

function settingsAttempt() {
  data.settingsCounter += 1
  if (data.settingsCounter === 3) {
    router.push({name: "settings"})
  }
  setTimeout(function () {
    data.settingsCounter = 0
  }, 1000)
}
</script>
