<template>
  <CornerBase :online="props.online">

    <template #give="{ givePoint, getPoints, actionRequired, removePoint }">
      <div class="row">
        <button class="col-3 vph-1 wb red give-point push-feedback" @mousedown="givePoint(true, 1)">{{ removePoint ? '-' : '' }}{{ t('red')}}</button>
        <div class="col-6 vph-1" id="menu-container" :class="{'actionRequired': actionRequired}">
          <CornerBaseMenuTrigger>
            <button @click="getPoints" class="vpw-2 vph-1" id="corner-base-menu-trigger-button">
              <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" id="logo" alt="Jirugi">
            </button>
          </CornerBaseMenuTrigger>
        </div>
        <button class="col-3 vph-1 wb blue give-point push-feedback" @mousedown="givePoint(false, 1)">{{ removePoint ? '-' : '' }}{{ t('blue') }}</button>
      </div>
    </template>

    <template #view="{ points, patterns, redTot, blueTot }">

      <div class="row top-color">
        <div class="col-6 red"></div>
        <div class="col-6 blue"></div>
      </div>

      <div class="text-center">
        <div v-if="patterns.length > 0">
          <div class="row points-row">
            <div class="col-12 point-label">{{ patterns[0] }}</div>
          </div>
          <div class="row">
            <div class="col-6">
              <span class="result" :class="{'red-winner': q(points[1].red['1'], 1) > q(points[1].blue['1'], 1)}">
                {{ p(points[1].red['1'], 1) }}
              </span>
            </div>
            <div class="col-6">
              <span class="result" :class="{'blue-winner': q(points[1].blue['1'], 1) > q(points[1].red['1'], 1)}">
                {{ p(points[1].blue['1'], 1) }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="patterns.length > 1">
          <div class="row points-row">
            <div class="col-12 point-label">{{ patterns[1] }}</div>
          </div>
          <div class="row">
            <div class="col-6">
              <span class="result" :class="{'red-winner': q(points[2].red['1'], 1) > q(points[2].blue['1'], 1)}">
                {{ p(points[2].red['1'], 1) }}
              </span>
            </div>
            <div class="col-6">
              <span class="result" :class="{'blue-winner': q(points[2].blue['1'], 1) > q(points[2].red['1'], 1)}">
                {{ p(points[2].blue['1'], 1) }}
              </span>
            </div>
          </div>
        </div>

        <div class="row points-row pt-5">
          <div class="col-12 point-label">Total</div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="result" :class="{'red-winner': q(redTot, patterns.length) > q(blueTot, patterns.length)}">
              {{ p(redTot, patterns.length) }}
            </span>
          </div>
          <div class="col-6">
            <span class="result" :class="{'blue-winner': q(blueTot, patterns.length) > q(redTot, patterns.length)}">
              {{ p(blueTot, patterns.length) }}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <button @click="zeroPattern(true)" class="btn btn-secondary">{{ t('zero_pattern') }}</button>
          </div>
          <div class="col-6">
            <button @click="zeroPattern(false)" class="btn btn-secondary">{{ t('zero_pattern') }}</button>
          </div>
        </div>

      </div>
    </template>

  </CornerBase>
</template>

<script setup>
import CornerBase from "@/Components/CornerBase.vue";
import CornerBaseMenuTrigger from "@/Components/CornerBaseMenuTrigger.vue";
import {reactive} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps(['online'])
const {t} = useI18n()
const data = reactive({
  selfUrl: SELF_URL,
})

function q(n, num_patterns) {
  return Math.max(0, num_patterns*10-0.2*n)
}
function p(n, num_patterns) {
  return (Math.round(q(n, num_patterns) * 10) / 10).toFixed(1)
}

function zeroPattern(redNotBlue) {
  console.log('zero pattern', redNotBlue)
}

</script>
