<template>
  <div @click="attempt">
    <slot></slot>
  </div>
</template>

<script setup>
import {reactive} from "vue";
import {useRouter} from "vue-router";

const props = defineProps(["routeName"])
const router = useRouter()
const data = reactive({
  counter: 0,
})

function attempt() {
  data.counter += 1
  if (data.counter === 3) {
    router.push({name: props.routeName})
  }
  setTimeout(function () {
    data.counter = 0
  }, 1000)
}
</script>
