import { createApp } from "vue"
import App from "./App"
import router from "./router";
import vueI18n from "./i18n"

import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/css/all.css"
import "./style.scss"
import axios from "axios";
import common from "@/common";
import bootstrap from 'bootstrap/dist/js/bootstrap'

const app = createApp(App)
app.use(router)
app.use(vueI18n)
//app.use(bootstrap)

let token = sessionStorage.getItem('accessToken')
if (token !== null) {
    axios.get(common.jirugiApi() + "/v1/check?token=" + sessionStorage.getItem('accessToken')).then(resp => {
        axios.defaults.headers.post['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`;
        if (typeof js_bridge !== 'undefined') {
            js_bridge.storageSet("accessToken", resp.data.token)
        }
        app.mount("#app")
    }).catch(err => {
        app.mount("#app")
        router.replace({name: "login"})
    })
} else {
    app.mount("#app")
    router.replace({name: "login"})
}
