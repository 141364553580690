<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="200" alt="JIRUGI">
        <h2 class="pb-5">{{ t('log') }}</h2>

        <button class="btn btn-warning" @click.prevent="router.back()">{{ t('back') }}</button>

        <div class="row" v-for="m in [...data.messages].reverse()">
          <div class="col-12"><small style="color: gray;">{{ m.time }}</small></div>
          <div class="offset-1 col-11" v-html="m.text"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive} from "vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

const {t} = useI18n()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  messages: [],
})

onMounted(() => {
  let m = sessionStorage.getItem("messages")
  if (m == null) {
    m = "[]"
  }
  data.messages = JSON.parse(m)
})
</script>
