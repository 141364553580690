export default {
    jirugiApi() {
        let jirugiApi = sessionStorage.getItem('jirugiApi')

        if (jirugiApi === null) {
            sessionStorage.setItem('jirugiApi', JIRUGI_API_URL)
            return JIRUGI_API_URL
        }

        return jirugiApi
    },
}
