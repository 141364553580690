<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <settings-trigger route-name="settings">
          <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="200" alt="JIRUGI">
        </settings-trigger>
        <h2 class="pb-5">{{ data.competition.name }}</h2>

        <!-- TODO: button here to go to the last used position at last ring -->

        <div v-for="ring in data.rings">
          <h4>{{ t("ring") }} {{ ring.name }} ({{ ring.id }})</h4>
          <div class="row">
            <div class="col-md-6">
              <button @click.prevent="set('jury', ring.id)" class="btn btn-primary">
                {{ t("jury") }}
              </button>
            </div>
            <div class="col-md-6">
              <!--<button @click.prevent="set('corner', ring.id)" class="btn btn-primary">
                {{ t("corner") }}
              </button>-->
              <button @click.prevent="set('sparring', ring.id)" class="btn btn-primary">
                {{ t("sparring") }}
              </button>
              <button @click.prevent="set('pattern', ring.id)" class="btn btn-primary">
                {{ t("pattern") }}
              </button>
            </div>
          </div>
        </div>

        <hr>

        <div v-if="data.superUmpireData !== ''">
          <button @click.prevent="set('', '')" class="btn btn-danger">{{ t("reset") }} ({{ data.superUmpireData }})</button>

          <hr>
        </div>

        <router-link :to="{name: 'home'}" class="btn btn-warning">{{ t("back") }}</router-link>

      </div>
    </div>
  </div>

</template>

<script setup>
import {onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import common from "@/common";
import axios from "axios";
import Settings from "@/Views/Settings.vue";
import SettingsTrigger from "@/Components/SettingsTrigger.vue";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  key: route.params.key,
  competition: {},
  rings: [],
  superUmpireData: '',
})

function set(position, ringId) {
  let p = position
  if (position === "sparring" || position === "pattern") {
    p = "corner"
  }
  axios.post(common.jirugiApi() + "/v1/set_super_umpire_info", {
    key: route.params.key,
    'ring_position': p,
    'ring_id': ringId
  }).then(resp => {
    if (position === "corner") {
      router.push({name: 'corner', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "sparring") {
      router.push({name: 'sparring', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "pattern") {
      router.push({name: 'pattern', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "jury") {
      router.push({name: 'jury', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "") {
      data.superUmpireData = ''
    } else {
      console.log("position not implemented.")
    }
  })
}

onMounted(() => {
  axios.post(common.jirugiApi() + "/v1/get_super_umpire_info", {key: route.params.key}).then(resp => {
    console.log(resp.data)
    if (resp.data.competition === null) {
      router.push({name: "home"})
      return
    }
    data.competition = resp.data.competition
    data.rings = resp.data.rings
    data.superUmpireData = resp.data.super_umpire_data
  })
})

</script>
